import $x from "@/libs/$x";

export default {
  namespaced: true,
  state: {
    roleList: [],
    sexList: [],
    productLabelList: [],
    productTypeList: [],
    vaildStaffList: [],
    serviceLabelList: [],
    craftsmanlist: [],
    serviceTypeList: [],
    productUnitList: [],
    
    adList: [],
  },
  mutations: {
    setRoleList: (state, data) => {
      state.roleList = data;
    },
    setSexList: (state, data) => {
      state.sexList = data;
    },
    setVaildStaffList: (state, data) => {
      state.vaildStaffList = data.map((item) => {
        return {
          key: item.userName,
          value: item.userName,
        };
      });
    },
    setCraftsmanlist: (state, data) => {
      state.craftsmanlist = data.map((item) => {
        return {
          key: item.userName,
          value: item.id,
        };
      });
    },
    setServiceLabelList: (state, data) => {
      state.serviceLabelList = data.map((item) => {
        return {
          key: item,
          value: item,
        };
      });
    },
    setServiceTypeList: (state, data) => {
      state.serviceTypeList = data.map((item) => {
        return {
          key: item,
          value: item,
        };
      });
    },
    setProductLabelList: (state, data) => {
      state.productLabelList = data.map((item) => {
        return {
          key: item,
          value: item,
        };
      });
    },
    setProductTypeList: (state, data) => {
      state.productTypeList = data.map((item) => {
        return {
          key: item,
          value: item,
        };
      });
    },
    setProductUnitList: (state, data) => {
      state.productUnitList = data.map((item) => {
        return {
          key: item.dictValue,
          value: item.dictValue,
        };
      });
    },

    SET_AD_LIST: (state, data) => {
      state.adList = data;
    },
  },
  actions: {
    getProductLabelList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/productLabel`
        );
        if (code === 200) {
          if (data) {
            commit("setProductLabelList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getProductUnitList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/common/productUnit`
        );
        if (code === 200) {
          if (data) {
            commit("setProductUnitList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getVaildStaffList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/stafflist`
        );
        if (code === 200) {
          if (data) {
            commit("setVaildStaffList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getCraftsmanlist({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/craftsmanlist`
        );
        if (code === 200) {
          if (data) {
            commit("setCraftsmanlist", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getServiceLabelList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/serviceLabel`
        );
        if (code === 200) {
          if (data) {
            commit("setServiceLabelList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getServiceTypeList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/serviceType`
        );
        if (code === 200) {
          if (data) {
            commit("setServiceTypeList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getProductTypeList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/productType`
        );
        if (code === 200) {
          if (data) {
            commit("setProductTypeList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getRoleList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/rolelist`
        );
        if (code === 200) {
          if (data) {
            commit("setRoleList", data);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
    getSexList({ commit, dispatch, getters }, shopId) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(
          `/platform/${shopId}/common/sexuallist`
        );
        if (code === 200) {
          if (data) {
            commit(
              "setSexList",
              data.map((item) => {
                return {
                  key: item.dictValue,
                  value: item.dictKey,
                };
              })
            );
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },

    getAdList({ commit, dispatch, getters }) {
      return new Promise(async (resolve, reject) => {
        const { data, code, message } = await $x.get(`/index/bannerAd/pages`);
        if (code === 200) {
          if (data) {
            commit("SET_AD_LIST", data.list);
            resolve();
          }
        } else {
          reject(message);
        }
      });
    },
  },
};

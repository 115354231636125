const Layer = (resolve) => require(["@/views/layer/layer.js"], resolve);

//概况
const General = (resolve) => require(["@/views/shop/general"], resolve);
//任务
const Task = (resolve) => require(["@/views/shop/task"], resolve);
//任务新增
const AddTask = (resolve) => require(["@/views/shop/taskAdd"], resolve);
//任务编辑
const EditTask = (resolve) => require(["@/views/shop/taskEdit"], resolve);
//任务详情
const DetailTask = (resolve) => require(["@/views/shop/taskDetail"], resolve);

//员工列表Layer
const StaffListLayer = (resolve) =>
  require(["@/views/shop/staffListLayer"], resolve);
//员工列表
const StaffList = (resolve) => require(["@/views/shop/staffList"], resolve);
//添加员工
const StaffAdd = (resolve) => require(["@/views/shop/staffAdd"], resolve);
//员工详情
const StaffDetail = (resolve) => require(["@/views/shop/staffDetail"], resolve);
//员工管理
const StaffEdit = (resolve) => require(["@/views/shop/staffEdit"], resolve);
//修改密码
const StaffPwd = (resolve) => require(["@/views/shop/staffPwd"], resolve);
//角色管理列表
const ActorList = (resolve) => require(["@/views/shop/actorList"], resolve);
//新增角色
const ActorAdd = (resolve) => require(["@/views/shop/actorAdd"], resolve);
//编辑角色
const ActorEdit = (resolve) => require(["@/views/shop/actorEdit"], resolve);
//角色详情
const ActorDetail = (resolve) => require(["@/views/shop/actorDetail"], resolve);
//已禁用员工管理
const StaffForbidEdit = (resolve) =>
  require(["@/views/shop/staffForbidEdit"], resolve);
//已禁用账号
const StaffForbid = (resolve) => require(["@/views/shop/staffForbid"], resolve);

//服务管理
const CarManage = (resolve) => require(["@/views/shop/carManage"], resolve);
//编辑服务
const CarEdit = (resolve) => require(["@/views/shop/carEdit"], resolve);

//服务管理
const ShopManage = (resolve) => require(["@/views/shop/shopManage"], resolve);
//编辑服务
const ShopEdit = (resolve) => require(["@/views/shop/shopEdit"], resolve);

//服务管理
const BlackList = (resolve) => require(["@/views/shop/blackList"], resolve);
//编辑服务
const CancelAccount = (resolve) =>
  require(["@/views/shop/cancelAccount"], resolve);

//服务管理
const ChargeManage = (resolve) =>
  require(["@/views/shop/chargeManage"], resolve);
//编辑服务
const ChargeEdit = (resolve) => require(["@/views/shop/chargeEdit"], resolve);

//服务管理
const ServiceManage = (resolve) =>
  require(["@/views/shop/serviceManage"], resolve);
//新增服务
const ServiceAdd = (resolve) => require(["@/views/shop/serviceAdd"], resolve);
//编辑服务
const ServiceEdit = (resolve) => require(["@/views/shop/serviceEdit"], resolve);
//服务详情
const ServiceMessage = (resolve) =>
  require(["@/views/shop/serviceMessage"], resolve);
//充电桩审核
const ServiceCheck = (resolve) =>
  require(["@/views/shop/serviceCheck"], resolve);
//充电桩审核
const ShopCheck = (resolve) => require(["@/views/shop/shopCheck"], resolve);
//充电桩审核
const CarCheck = (resolve) => require(["@/views/shop/carCheck"], resolve);
//充电桩审核
const ServiceCheckDetail = (resolve) =>
  require(["@/views/shop/serviceCheckDetail"], resolve);
//项目管理
const ProjectManage = (resolve) =>
  require(["@/views/shop/projectManage"], resolve);
//新增项目
const ProjectAdd = (resolve) => require(["@/views/shop/projectAdd"], resolve);
//编辑项目
const ProjectEdit = (resolve) => require(["@/views/shop/projectEdit"], resolve);
//项目详情
const ProjectDetail = (resolve) =>
  require(["@/views/shop/projectDetail"], resolve);
//类目管理
const CategoryManage = (resolve) =>
  require(["@/views/shop/categoryManage"], resolve);
//新增类目
const CategoryAdd = (resolve) => require(["@/views/shop/categoryAdd"], resolve);
//编辑类目
const CategoryEdit = (resolve) =>
  require(["@/views/shop/categoryEdit"], resolve);
//类目详情
const CategoryDetail = (resolve) =>
  require(["@/views/shop/categoryDetail"], resolve);

const MyInfo = (resolve) => require(["@/views/shop/myInfo"], resolve);

//我的
const My = (resolve) => require(["@/views/shop/my"], resolve);
//图像处理
const ImageHandler = (resolve) =>
  require(["@/views/shop/imageHandler"], resolve);
//图像特效
const ImageEffect = (resolve) => require(["@/views/shop/imageEffect"], resolve);
//图像处理实际操作页面
const ImageStudio = (resolve) => require(["@/views/shop/imageStudio"], resolve);

//充值
const Recharge = (resolve) => require(["@/views/shop/recharge"], resolve);
//客服
const LinkUs = (resolve) => require(["@/views/shop/linkUs"], resolve);
//充值记录
const MyRecharge = (resolve) => require(["@/views/shop/myRecharge"], resolve);
//使用记录
const MyOrder = (resolve) => require(["@/views/shop/myOrder"], resolve);
//设置
const Setting = (resolve) => require(["@/views/shop/setting"], resolve);
//注销账号
const RemoveAccount = (resolve) =>
  require(["@/views/shop/removeAccount"], resolve);
//用户协议
const ServiceProtocol = (resolve) =>
  require(["@/views/shop/serviceProtocol"], resolve);
//隐私政策
const PrivacyPolicy = (resolve) =>
  require(["@/views/shop/privacyPolicy"], resolve);
/**
 * 多级子路由在Layer的children后面继续添加 不需要再在children中嵌套children
 */

import { $t } from "@/i18n";

const shopRouter = [
  // {
  //   path: '/car',
  //   meta: {
  //     title: '我的车辆',
  //     icon: 'iconfont al-icon-neirongmoxingshezhi',
  //     icon_m: 'iconfont al-icon-neirongmoxingshezhi',
  //     show: true
  //   },
  //   redirect: '/car/carManage/index',
  //   component: Layer,
  //   children: [{
  //     path: '/car/carManage/edit',
  //     name: 'carEdit',
  //     component: CarEdit,
  //     meta: {
  //       title: '编辑车辆',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }, {
  //     path: '/car/carManage/index',
  //     name: 'carManage',
  //     component: CarManage,
  //     meta: {
  //       title: '我的车辆',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }]
  // },
  // {
  //   path: '/charge',
  //   meta: {
  //     title: '充电桩',
  //     icon: 'iconfont al-icon-neirongmoxingshezhi',
  //     icon_m: 'iconfont al-icon-neirongmoxingshezhi',
  //     show: true
  //   },
  //   redirect: '/charge/chargeManage/index',
  //   component: Layer,
  //   children: [{
  //     path: '/charge/chargeManage/edit',
  //     name: 'chargeEdit',
  //     component: ChargeEdit,
  //     meta: {
  //       title: '编辑充电桩',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }, {
  //     path: '/charge/chargeManage/index',
  //     name: 'chargeManage',
  //     component: ChargeManage,
  //     meta: {
  //       title: '我的充电桩',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }]
  // },
  // {
  //   path: '/shop',
  //   meta: {
  //     title: '我的店铺',
  //     icon: 'iconfont al-icon-neirongmoxingshezhi',
  //     icon_m: 'iconfont al-icon-neirongmoxingshezhi',
  //     show: true
  //   },
  //   redirect: '/shop/shopManage/index',
  //   component: Layer,
  //   children: [{
  //     path: '/shop/shopManage/edit',
  //     name: 'shopEdit',
  //     component: ShopEdit,
  //     meta: {
  //       title: '编辑店铺',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }, {
  //     path: '/shop/shopManage/index',
  //     name: 'shopManage',
  //     component: ShopManage,
  //     meta: {
  //       title: '我的店铺',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: false
  //     }
  //   }]
  // },
  // {
  //   path: '/setting',
  //   meta: {
  //     title: '设置',
  //     icon: 'iconfont al-icon-neirongmoxingshezhi',
  //     icon_m: 'iconfont al-icon-neirongmoxingshezhi',
  //     show: true
  //   },
  //   redirect: '/setting/blackList',
  //   component: Layer,
  //   children: [{
  //     path: '/setting/blackList',
  //     name: 'blackList',
  //     component: BlackList,
  //     meta: {
  //       title: '黑名单',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: true
  //     }
  //   }, {
  //     path: '/setting/cancelAccount',
  //     name: 'cancelAccount',
  //     component: CancelAccount,
  //     meta: {
  //       title: '注销账号',
  //       icon: 'el-icon-menu',
  //       icon_m: 'md-apps',
  //       show: true
  //     }
  //   }]
  // },
  // {
  //   path: "/myInfo",
  //   meta: {
  //     title: "个人信息",
  //     icon: "iconfont al-icon-gaikuang",
  //     icon_m: "iconfont al-icon-gaikuang",
  //     show: false,
  //   },
  //   redirect: "/myInfo/index",
  //   component: Layer,
  //   children: [
  //     {
  //       path: "/myInfo/index",
  //       name: "myInfo",
  //       component: MyInfo,
  //       meta: {
  //         title: "个人信息",
  //         icon: "el-icon-menu",
  //         icon_m: "md-apps",
  //         show: false,
  //       },
  //     },
  //   ],
  // },

  {
    path: "/image",
    meta: {
      title: $t("gong-neng"),
      titleKey: "gong-neng",
      icon: "iconfont al-icon-neirongmoxingshezhi",
      icon_m: "iconfont al-icon-neirongmoxingshezhi",
      show: true,
    },
    redirect: "/image/imageHandler",
    component: Layer,
    children: [
      {
        path: "/image/imageHandler",
        name: "imageHandler",
        component: ImageHandler,
        meta: {
          title: $t("tu-xiang-chu-li"),
          titleKey: "tu-xiang-chu-li",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: true,
        },
      },
      {
        path: "/image/imageEffect",
        name: "imageEffect",
        component: ImageEffect,
        meta: {
          title: $t("tu-xiang-te-xiao"),
          titleKey: "tu-xiang-te-xiao",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: true,
        },
      },
      {
        path: "/image/imageStudio",
        name: "imageStudio",
        component: ImageStudio,
        meta: {
          title: "",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
    ],
  },
  {
    path: "/home",
    meta: {
      title: $t("wo-de"),
      titleKey: "wo-de",
      icon: "iconfont al-icon-neirongmoxingshezhi",
      icon_m: "iconfont al-icon-neirongmoxingshezhi",
      show: true,
    },
    redirect: "/my/index",
    component: Layer,
    children: [
      {
        path: "/my/index",
        name: "my",
        component: My,
        meta: {
          title: "个人信息",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/my/recharge",
        name: "recharge",
        component: Recharge,
        meta: {
          title: "充值",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/my/linkUs",
        name: "linkUs",
        component: LinkUs,
        meta: {
          title: "客服",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/my/myRecharge",
        name: "myRecharge",
        component: MyRecharge,
        meta: {
          title: "充值记录",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/my/myOrder",
        name: "myOrder",
        component: MyOrder,
        meta: {
          title: "使用记录",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/my/setting",
        name: "setting",
        component: Setting,
        meta: {
          title: "设置",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/my/setting/removeAccount",
        name: "removeAccount",
        component: RemoveAccount,
        meta: {
          title: "注销账号",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/my/setting/serviceProtocol",
        name: "serviceProtocol",
        component: ServiceProtocol,
        meta: {
          title: "用户协议",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
      {
        path: "/my/setting/privacyPolicy",
        name: "privacyPolicy",
        component: PrivacyPolicy,
        meta: {
          title: "隐私政策",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
    ],
  },
  {
    path: "/myInfo",
    meta: {
      title: "个人信息",
      icon: "iconfont al-icon-gaikuang",
      icon_m: "iconfont al-icon-gaikuang",
      show: false,
    },
    redirect: "/myInfo/index",
    component: Layer,
    children: [
      {
        path: "/myInfo/index",
        name: "myInfo",
        component: MyInfo,
        meta: {
          title: "个人信息",
          icon: "el-icon-menu",
          icon_m: "md-apps",
          show: false,
        },
      },
    ],
  },
];

export default shopRouter;

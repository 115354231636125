import $x from '@/libs/$x.js'

const initView = (pcView, mobileView, ...mixin) => {
  let mainView = pcView
  // let isPc = $x.isPc
  // if (!isPc) {
  //   mainView = mobileView
  // }
  if (mixin.length > 0) {
    mainView.mixins = mainView.mixin ? [...mainView.mixin, ...mixin] : mixin
  }
  return mainView
}
export default initView